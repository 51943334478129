/* 
    styles for topbar, banner, nav elements
*/

/* top bar */
#top-bar {
    font-size: 1rem;
}
#topbar-ui-home {
    //color: $grey-color;
}

/* banner */
.lib-logo-lg {
    max-height: 80px;
}
.btn-ask {
    background-color: transparent;
    background-image: none;
    border: 4px double $pride-gold;
    &:hover{
        background-color: $pride-gold;
        color:white;
    }
}

/* main nav */
#main-nav {
    background: $nav-background;
    ul {
        //font-size: 18px;
    }
    .nav-link {
        color: $link-color;
    }
}
#mobilemenu {
    .modal-header {
        border-bottom: none;
        padding: 1rem 1rem 0 0;
    }
    .modal-body {
        padding: 0 1rem 1rem 1rem;
    }
    ul {
        font-size: 24px;
    }
}
/* big dropdown */
.dropdown-menu {
    .container {
        width:1300px;
        margin-right:-17px;

        @media (min-width: 768px) and (max-width: 900px){
            width:740px !important;
        }
    }
}
.popular {
    width: 90%;
    color: #191919;
    text-decoration: none;
    font-weight: bold;
}
