/* 
    styles for content pages, 
    providing split background look for sidebar nav
*/
// section title 
#page-title {
    background: $black-color;
    color: $light-text-color;
}
// page nav
.split-background {
    background: linear-gradient(
        to right, 
        $nav-background 0%,
        $nav-background 50%,
        $background-color 50%,
        $background-color 100%
    );
    @include media-query($on-palm) {
        background: $background-color;
    }
}
#sidebar {
    background: $nav-background;
}
#page-nav {
    font-size: $base-font-size * 1.05;
    a {
        font-weight: 300 !important;
    }
    li { border-color: #d5d5d5 !important; }
}
.page-block { 
    background: $background-color;
}
.breadcrumb {
    background: $background-color;
}
/* full width page */
.full-background {
    background: $nav-background;
}

/* narrow text block for page-narrow layout */
.page-narrow {
    max-width: 700px;
}
/* card-button class for section index pages to avoid display issues */
.card-button {
    cursor: pointer;
    &:hover { text-decoration: none; }
    .card:hover { background: #f2f2f2; }
    h5:hover { text-decoration: underline; }
    p { font-weight: 400; }
}
