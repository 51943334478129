/*  
    base - slight modifications to override base Bootstrap
    these values are configurable by editing base variables in main.scss
*/
body {
    @if $base-font-family != "" { font-family: $base-font-family; }
    @if $base-font-size != "" { font-size: $base-font-size; }
    @if $text-color != "" { color: $text-color; }
}

/* set heading and btn sizes relative to base font size following Bootstrap method */
@if $base-font-size != "" {

    $h1-font-size:                $base-font-size * 2.5 !default;
    $h2-font-size:                $base-font-size * 2 !default;
    $h3-font-size:                $base-font-size * 1.75 !default;
    $h4-font-size:                $base-font-size * 1.5 !default;
    $h5-font-size:                $base-font-size * 1.25 !default;
    $h6-font-size:                $base-font-size !default;

    $btn-font-size:               $base-font-size !default;
    $btn-font-size-sm:            $base-font-size * .875 !default;
    $btn-font-size-lg:            $base-font-size * 1.25 !default;

    h1, .h1 { font-size:$h1-font-size; }
    h2, .h2 { font-size:$h2-font-size; }
    h3, .h3 { font-size:$h3-font-size; }
    h4, .h4 { font-size:$h4-font-size; }
    h5, .h5 { font-size:$h5-font-size; }
    h6, .h6 { font-size:$h6-font-size; }

    .btn { font-size:$btn-font-size; }
    .btn-lg { font-size:$btn-font-size-lg; }
    .btn-sm { font-size:$btn-font-size-sm; }
    .form-control { font-size:$btn-font-size; }
    .form-control-lg { font-size:$btn-font-size-lg; }

}

/* set link color and decoration */
a {
    @if $link-color != "" { 
        color: $link-color; 
        text-decoration: none;
        font-weight: bold;
        &:hover {
            @if $text-color != "" { color: $text-color; }
            text-decoration: underline;
        }
    }
}

/* tweak container size */
.container {
    @media (min-width: 576px) and (max-width: 768px){
        max-width: 100%;
        padding:inherit 10px;
    }
    @media (min-width: 768px) and (max-width: 900px){
        max-width: 740px;
    }
    @media (min-width: 1400px){
        max-width: 1300px;
    }
}

/* skip nav for accessibility, based on https://webaim.org/techniques/skipnav/ */
#skip-to-content a {
	padding:6px;
	position: absolute;
	top:-45px;
	left:0px;
	color:white;
	border-right:1px solid white;
	border-bottom:1px solid white;
	border-bottom-right-radius:8px;
	background:#BF1722;
	-webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
}
#skip-to-content a:focus {
	position:absolute;
	left:0px;
	top:0px;
	outline-color:transparent;	
	-webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
}
