@charset "utf-8";

// base variables
$base-font-family: 'Public Sans', sans-serif; 
$base-font-size:   1.15rem;
$text-color:       #111;
$light-text-color: #fdfdfd;

$background-color: #fdfdfd;
$nav-background: #e8e8e8; //#CBCCD0;
$link-color:      #191919;

$grey-color:       #808080;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$black-color:       #191919;
$black-color-light: lighten($black-color, 40%);
$black-color-dark:  darken($black-color, 25%);

// Width of the content area
$content-width:    1800px;
$on-palm:          576px;
$on-laptop:        768px;
$on-desktop:       992px;
$on-jumbo:        1200px;
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

/* set up theme color variables from config_theme-colors.csv */
 

$pride-gold: #F1B300;

$clearwater: #00AEC7;

$clearwater-light: #8BD5E5;

$payette-blue: #003E51;

$ponderosa-pine: #004E42;

$garnet: #D22630;

$wild-rose: #E10098;

$palouse-green: #00AF66;

$black-color: #191919;

$grey-color: #808080;

/* add colors to theme with _theme-colors to create btn, btn-outline, text color, bg, and border */
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    
    "pride-gold": $pride-gold,
    
    "clearwater": $clearwater,
    
    "clearwater-light": $clearwater-light,
    
    "payette-blue": $payette-blue,
    
    "ponderosa-pine": $ponderosa-pine,
    
    "garnet": $garnet,
    
    "wild-rose": $wild-rose,
    
    "palouse-green": $palouse-green,
    
    "black-color": $black-color,
    
    "grey-color": $grey-color
    
  ),
  $theme-colors
);

@import "boot4";

@import "theme-colors", "page";

#libapps-custom {
    @import "base", "header", "footer", "custom";
}

@import "libapps";
