/* footer */
footer, footer a {
    color:white !important;
    font-weight: 300;
    background-color: $black-color;
}

#page-nav .active {
    background-color: #fff !important; 
    border-right: 2px solid;
    border-right-color: #191919 !important;
}
#footer-logo {
    max-height: 120px;
}
