/* 
    other random CSS 
*/

/* libchat widget floating */
// @media (max-width: 576px) { .s-lch-widget-float{ display: none!important;} }

/* tweak alerts with markdown inside */
.alert p:last-of-type { margin-bottom: 0; }

/* tweak spacing of dropdown triangle thing */
.dropdown-toggle::after {
    margin-left: 15px;
}

/* Circle buttons */
.btn-circle { 
    width: 180px; 
    height: 180px; 
    padding: 10px; 
    border-radius: 90px; 
    font-size: 18px; 
    text-align: center; 
    overflow: hidden;
    &:hover{
        color:white !important;
    }
} 

/* my library */ 
#mylibrary-carousel { 
    .carousel-caption { 
        background: rgba(0, 0, 0, 0.7); 
        min-width:450px;
        width:auto;
        bottom: 30px;
    }
    .carousel-indicators {
        background: rgba(0, 0, 0, 0.7);
    }

    background: rgba(0, 0, 0, 0.7); 
    color: white;
    a {
        color:white !important;
    }
}

/* bs icons */
.bi {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: -.125em;
    fill: currentcolor;
}

/* embed responsive extras */
.embed-responsive-10by6::before{
    padding-top:64.5%
}